import moment from 'moment-timezone/builds/moment-timezone-with-data';
import { convertStringToReadableTime } from './vue/components/utils';

$(() => {
  const init = () => {
    tutorContactHoursTooltip();
  };

  const tutorContactHoursTooltip = () => {
    let desktopTip = $("#desktop-contact-hours-tip");
    let mobileTip = $("#mobile-contact-hours-tip");
    let data = desktopTip.data();
    let tipText = '';

    // data attributes are set to false if values from DB are empty
    if (data['starttime'] == data['endtime'] || Object.values(data).includes(false)) {
      tipText = 'Note that average response times may vary depending on a variety of factors, including time of day.';
    }
    else {
      tipText = `Note that average response time is based on ${ data['firstname'] }'s active hours, which are ${convertStringToReadableTime(data['starttime'], data['timezone'], false, moment) } - ${convertStringToReadableTime(data['endtime'], data['timezone'], true, moment) }.`;
    }  

    desktopTip.text(tipText);
    mobileTip.text(tipText);
  }
  
  init();
});

